<template>
  <a-card :bodyStyle="{ padding: '32px' }">
    <a-form-model v-bind="$formItemLayout4Detail" style="margin-top:32px">
      <a-form-model-item label="阶段名称">
        <span>{{ dataSource.name }}</span>
      </a-form-model-item>

      <a-form-model-item label="教学视频" v-if="dataSource.teachingVideo && dataSource.teachingVideo.url">
        <div style="margin-top:0">
          <a :href="dataSource.teachingVideo && dataSource.teachingVideo.url" target="blank_"
            ><a-icon type="file" style="margin-right:8px" />{{
              dataSource.teachingVideo && dataSource.teachingVideo.name
            }}</a
          >
        </div>
      </a-form-model-item>

      <a-form-model-item label="阶段分类">
        <span>{{ dataSource.field.name || '-' }}</span>
      </a-form-model-item>

      <a-form-model-item label="记录方式">
        <span>{{ dataSource.recordingMethod | RecordingMethodText() }}</span>
      </a-form-model-item>

      <a-form-model-item label="阶段介绍">
        <p class="pre" style="margin-top:0">{{ dataSource.introduction }}</p>
      </a-form-model-item>

      <a-form-model-item label="步骤描述">
        <div v-for="(desc, index) in dataSource.stepsDescriptionList" :key="index">
          <span style="margin-left:8px">{{ desc }}</span>
        </div>
      </a-form-model-item>

      <a-form-model-item label="注意事项">
        <p class="pre" style="margin-top:0">{{ dataSource.precautions }}</p>
      </a-form-model-item>

      <a-form-model-item label="状态">
        <a-badge
          :status="dataSource.status === 'AVAILABLE' ? 'success' : 'error'"
          :text="dataSource.status | StatusText()"
        />
      </a-form-model-item>

      <a-form-model-item label="动画文件">
        <div style="margin-top:0">
          <div v-for="item in dataSource.resourceList" :key="item.id">
            <a :href="item.url" target="blank_"><a-icon type="file" style="margin-right:8px" />{{ item.name }}</a>
          </div>
        </div>

        <div style="margin-top:16px;">
          本素材动画共分为
          <span class="primary-color" style="margin:0 16px;">{{ dataSource.steps - 2 }}</span>
          步，数据记录将出现在第
          <span class="primary-color" style="margin:0 16px;">{{ dataSource.trainingStep }}</span>
          步后
        </div>

        <div>
          最低回合数
          <span class="primary-color" style="margin:0 16px;">{{ dataSource.minimumRounds }}</span>
          回合
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>
<script>
import * as phaseApi from '@/api/phase'
import { convertToChineseNumber } from '@/utils/util'

export default {
  name: 'SkillManagementPhaseDetail',
  data() {
    return {
      dataSource: {
        id: 0,
        name: '',
        field: {
          code: '',
          id: 0,
          introduction: '',
          isShow: true,
          level: '',
          name: '',
          sequence: 0,
          url: ''
        },
        recordingMethod: '',
        introduction: '',
        stepsDescriptionList: [],
        precautions: '',
        status: 'AVAILABLE',
        resourceList: [],
        steps: 0,
        trainingStep: 0,
        minimumRounds: 0
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    convertToChineseNumber,
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this

      phaseApi.detail(id).then(res => {
        this.dataSource = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-control-wrapper {
  padding-left: 16px;
}
/deep/ .ant-form-item {
  margin-bottom: 32px;
}
/deep/ .ant-form-item-label,
/deep/ .ant-form-item-control,
p.pre {
  line-height: 2;
}
</style>
