import request from '@/utils/request'

const phaseApi = {
  list: '/phases',
  create: '/phases',
  update: id => `/phases/${id}`,
  detail: id => `/phases/${id}`,
  remove: id => `/phases/${id}`,
  updateStatus: id => `/phases/status/${id}`
}

export function list(parameter) {
  return request({
    url: phaseApi.list,
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: phaseApi.create,
    method: 'post',
    data: parameter
  })
}

export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: phaseApi.update(id),
    method: 'put',
    data: parameter
  })
}

export function detail(parameter) {
  return request({
    url: phaseApi.detail(parameter),
    method: 'get'
  })
}

export function remove(parameter) {
  return request({
    url: phaseApi.remove(parameter),
    method: 'delete'
  })
}

export function updateStatus(parameter) {
  return request({
    url: phaseApi.updateStatus(parameter),
    method: 'put'
  })
}
